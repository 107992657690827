import React, { Component } from 'react';

class NotFound extends Component {
  state = {  }
  render() { 
    return ( 
      <h1>Oops... Page not found.</h1>
     );
  }
}
 
export default NotFound;